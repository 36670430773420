import { Fragment, useMemo, type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useNavigation,
    type HttpError,
    useCan,
} from '@refinedev/core';
import { List, useTable, EditButton, TextField } from '@refinedev/antd';
import { Table, Space, Col, Row } from 'antd';

import CustomDeleteButton from '@/components/CustomDeleteButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import ScoresFieldDisplay from './ScoresFieldDisplay';
import FactoryBrandsFilters from './FactoryBrandsFilters';

import useTableSettings from '@/hooks/useTableSettings';
import onClickCapture from '@/utils/onClickCapture';
import onSearch, { type SearchParams } from './FactoryBrandsFilters/onSearch';
import { errorNotification } from '@/notifications';
import type { TableColumn } from '@/interfaces';
import type { FactoryBrandScore, IFactoryBrand } from '@/interfaces/factoryBrands';

type FactoryBrandColumns = TableColumn<IFactoryBrand, keyof IFactoryBrand | 'actions'>[];

const tBase = 'factoryBrands.list';

export const FactoryBrandsList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({
        resource: 'factoryBrands',
        action: 'create',
    });
    const { data: { can: canEdit } = {} } = useCan({ resource: 'factoryBrands', action: 'edit' });
    const { data: { can: canDelete } = {} } = useCan({
        resource: 'factoryBrands',
        action: 'delete',
    });

    const { tableProps, searchFormProps } = useTable<IFactoryBrand, HttpError, SearchParams>({
        onSearch,
        errorNotification,
        sorters: {
            initial: [{ field: 'name', order: 'asc' }],
        },
    });

    const columns: FactoryBrandColumns = useMemo(
        () => [
            {
                name: 'name',
                sorter: true,
                render: (value: IFactoryBrand['name']) => <TextField value={value} />,
            },
            {
                name: 'listing',
                sorter: true,
                render: (value: IFactoryBrand['listing']) => <TextField value={value} />,
            },
            {
                name: 'lastScore',
                sorter: false,
                render: (value?: FactoryBrandScore) => <ScoresFieldDisplay value={value} />,
            },
            {
                name: 'actions',
                render: (_value: void, entity) => (
                    <Space>
                        <EditButton
                            hideText={true}
                            size='small'
                            disabled={!canEdit}
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />
                        <CustomDeleteButton
                            hideText={true}
                            size='small'
                            disabled={!canDelete}
                            recordItemId={entity.id}
                            mutationMode='undoable'
                            onClick={onClickCapture}
                        />
                    </Space>
                ),
            },
        ],
        [canEdit, canDelete],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'factory-brands',
    );

    return (
        <List
            title={t('factoryBrands.titles.list')}
            canCreate={canCreate}
            headerButtons={({ defaultButtons }) => (
                <Fragment>
                    {defaultButtons}
                    <TableSettingsButton
                        title={t(`${tBase}.tableSettings.title`)}
                        columnsList={columnsList}
                        settings={settings}
                        onSettingsChange={onSettingsChange}
                    />
                </Fragment>
            )}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={8} md={6}>
                    <FactoryBrandsFilters formProps={searchFormProps} />
                </Col>
                <Col xs={24} sm={16} md={18}>
                    <Table
                        {...tableProps}
                        rowKey='id'
                        rowClassName='cursor-pointer'
                        className='table-with-header-cell-nowrap'
                        onRow={(entity) => {
                            return {
                                onClick: () => show('factoryBrands', entity.id),
                            };
                        }}
                    >
                        {columns.map(({ name, title, hidden, ...columnProps }) => {
                            const key = Array.isArray(name) ? name.join('.') : name;
                            return (
                                <Table.Column
                                    {...columnProps}
                                    dataIndex={name}
                                    key={key}
                                    hidden={hidden ?? !settings[key]}
                                    title={title ?? t(`${tBase}.fields.${key}`)}
                                />
                            );
                        })}
                    </Table>
                </Col>
            </Row>
        </List>
    );
};
