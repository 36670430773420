import type { AuditNcChartItem } from '@/interfaces/audits';
import type { IAudit, IAuditNCInfo } from '@/interfaces/audits';
import { AuditNCCASeverity } from '@/interfaces/enums/audits';

export type Result = AuditNcChartItem[];

const severities = Object.values(AuditNCCASeverity);

const getAuditNcsChartItems = (ncsInfo?: IAuditNCInfo[], audits?: IAudit[]): Result => {
    const result: Result = [];

    const sortedNcs = ncsInfo?.map((ncsItem) => {
        let max = 0;
        const ncsSeverities = severities.map((severity) => {
            let count: number = 0;
            audits?.forEach((audit) => {
                audit.ncs?.forEach((item) => {
                    if (item.name?.id === ncsItem.id || item.nameId === ncsItem.id) {
                        count += Number(item[severity]) || 0;
                    }
                });
            });

            max = Math.max(max, count);

            return {
                name: ncsItem.value,
                severity,
                count,
            };
        });

        ncsSeverities.sort((a, b) => b.count - a.count);
        return { max, items: ncsSeverities };
    });

    sortedNcs?.sort((a, b) => b.max - a.max);
    sortedNcs?.forEach(({ items }) => {
        result.push(...items);
    });

    return result;
};

export default getAuditNcsChartItems;
