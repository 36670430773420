import { type FunctionComponent, Fragment, useCallback } from 'react';
import { useTranslate } from '@refinedev/core';
import useSemiconductorTypesOptions from '@/hooks/options/useSemiconductorTypesOptions';
import { Form, Input, Row, Col, Button, InputNumber, Space, Select } from 'antd';
import antdSelectFilter from '@/utils/antdSelectFilter';
import antdSelectSort from '@/utils/antdSelectSort';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import { IProductType } from '@/interfaces/productTypes';

export type ProductModelValue = {
    name?: string;
    output?: number | string;
};

export type Props = {
    tBase: string;
    /** @todo Display `semiconductorTypeId` list from productType */
    // productType?: IProductType;
    isPvModules?: boolean;
};

const ProductModelsField: FunctionComponent<Props> = ({ tBase, isPvModules }) => {
    const t = useTranslate();

    const onFieldValidate = useCallback(
        async (name: string, index: number, value?: string | number) => {
            if (value == null || !`${value}`.trim()) {
                throw new Error(t(`${tBase}.rules.${name}.required`, { index: index + 1 }));
            }
        },
        [t, tBase],
    );

    const { data: semiconductorTypesOptions } = useSemiconductorTypesOptions();

    return (
        <Form.List name='productModels'>
            {(fields, { add, remove }, { errors }) => (
                <Fragment>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            {fields.map(({ key, name: fieldKey, ...fieldProps }) => (
                                <Fragment key={key}>
                                    <Space.Compact>
                                        <Form.Item
                                            {...fieldProps}
                                            name={[fieldKey, 'name']}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: (_rule: any, value?: string) =>
                                                        onFieldValidate(
                                                            'productModelName',
                                                            fieldKey,
                                                            value,
                                                        ),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={t(`${tBase}.fields.productModelName`)}
                                                autoComplete='off'
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...fieldProps}
                                            name={[fieldKey, 'total']}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: (_rule: any, value?: string) =>
                                                        onFieldValidate(
                                                            'productModelTotal',
                                                            fieldKey,
                                                            value,
                                                        ),
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                min={0}
                                                autoComplete='off'
                                                controls={false}
                                                changeOnWheel={false}
                                                placeholder={t(`${tBase}.fields.productModelTotal`)}
                                                className='w-full'
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...fieldProps}
                                            name={[fieldKey, 'power']}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: (_rule: any, value?: string) =>
                                                        onFieldValidate(
                                                            'productModelPower',
                                                            fieldKey,
                                                            value,
                                                        ),
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                type='number'
                                                min={0}
                                                autoComplete='off'
                                                controls={false}
                                                changeOnWheel={false}
                                                placeholder={t(`${tBase}.fields.productModelPower`)}
                                                className='w-full'
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...fieldProps}
                                            name={[fieldKey, 'cellDesignStructure']}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: (_rule: any, value?: string) =>
                                                        onFieldValidate(
                                                            'productModelCellDesignStructure',
                                                            fieldKey,
                                                            value,
                                                        ),
                                                },
                                            ]}
                                            hidden={!isPvModules}
                                        >
                                            <Input
                                                placeholder={t(
                                                    `${tBase}.fields.productModelCellDesignStructure`,
                                                )}
                                                autoComplete='off'
                                                className='w-full'
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...fieldProps}
                                            name={[fieldKey, 'semiconductorTypeId']}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: (_rule: any, value?: string) =>
                                                        onFieldValidate(
                                                            'productModelSemiconductorType',
                                                            fieldKey,
                                                            value,
                                                        ),
                                                },
                                            ]}
                                            hidden={!isPvModules}
                                        >
                                        {
                                        <Select
                                            placeholder={t(`${tBase}.fields.productModelSemiconductorType`,)}
                                            showSearch={true}
                                            allowClear={true}
                                            disabled={!isPvModules}
                                            //optionFilterProp='label'
                                            filterOption={antdSelectFilter}
                                            filterSort={antdSelectSort}
                                            options={semiconductorTypesOptions}
                                        />
                                        }
                                        </Form.Item>
                                    </Space.Compact>
                                    <Button
                                        type='link'
                                        className='dynamic-delete-button'
                                        onClick={() => remove(fieldKey)}
                                    >
                                        <MinusCircleOutlined />
                                    </Button>
                                </Fragment>
                            ))}
                        </Col>
                        <Col xs={24}>
                            <Button
                                type='dashed'
                                icon={<PlusOutlined />}
                                style={{ marginBottom: 16 }}
                                onClick={() => add()}
                            >
                                {t(`${tBase}.buttons.addProductModel`)}
                            </Button>
                        </Col>
                    </Row>
                    <Form.ErrorList errors={errors} />
                </Fragment>
            )}
        </Form.List>
    );
};

export default ProductModelsField;
