import { type FunctionComponent, useMemo } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useNavigation,
    useCan,
    type HttpError,
    useApiUrl,
    useCustom,
} from '@refinedev/core';
import { List, TextField, useTable, EditButton } from '@refinedev/antd';
import { Table, Col, Row, Space } from 'antd';

import { REACT_QUERY_MICRO_CACHE_TTL, DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import CustomDeleteButton from '@/components/CustomDeleteButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import AuditResultBadge from '@/components/AuditResultBadge';
import AuditScoreResultChart from '@/components/AuditScoreResultChart';
import AuditNcsChart from '@/components/AuditNcsChart';
import AuditsFilters from './AuditsFilters';

import useTableSettings from '@/hooks/useTableSettings';
import onClickCapture from '@/utils/onClickCapture';
import downloadAuditReport from '@/utils/audit/downloadAuditReport';
import getFactoryTitle from '@/utils/factory/getFactoryTitle';
import { errorNotification } from '@/notifications';
import onSearch, { type SearchParams } from './AuditsFilters/onSearch';
import type { TableColumn } from '@/interfaces';
import type { IAudit, IAuditScoreResultChartData } from '@/interfaces/audits';
import type { IProject } from '@/interfaces/projects';
import type { IProductType } from '@/interfaces/productTypes';

type AuditColumns = TableColumn<
    IAudit,
    keyof IAudit | ['project', 'name'] | ['productType', 'name'] | 'actions'
>[];

const tBase = 'audits.list';

export const AuditsList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'audits', action: 'create' });

    const { tableProps, searchFormProps, filters } = useTable<IAudit, HttpError, SearchParams>({
        onSearch,
        errorNotification,
        sorters: {
            initial: [{ field: 'createdAt', order: 'desc' }],
        },
    });

    const audits = useMemo(
        () => (tableProps.dataSource ? [...tableProps.dataSource] : []),
        [tableProps.dataSource],
    );

    const { data: auditScoreResultChartData } = useCustom<IAuditScoreResultChartData>({
        url: `${apiUrl}/audits/average`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
        },
        config: { filters },
    });

    const columns: AuditColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value?: IAudit['name']) => <TextField value={value || DEFAULT_NA_VALUE} />,
                sorter: true,
            },
            {
                name: 'type',
                render: (value?: IAudit['type']) => (
                    <TextField
                        value={
                            value?.value
                                ? t(`audits.auditType.${value.value}`, value.value)
                                : DEFAULT_NA_VALUE
                        }
                    />
                ),
                sorter: true,
            },
            {
                name: ['project', 'name'],
                render: (value?: IProject['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: ['productType', 'name'],
                render: (value?: IProductType['name']) => (
                    <TextField value={value?.value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'factory',
                render: (value?: IAudit['factory']) => (
                    <TextField value={getFactoryTitle(value) || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'scoreResult',
                render: (value?: IAudit['scoreResult'] | undefined, entity?: IAudit) => (
                    <AuditResultBadge scoreResult={value} totalScore={entity?.totalScore} />
                ),
                sorter: true,
            },
            {
                name: 'actions',
                render: (_value: void, entity: IAudit) => (
                    <Space>
                        <DownloadButton
                            hideText={true}
                            size='small'
                            title={t(`${tBase}.buttons.downloadAuditReport`)}
                            disabled={!entity.isReportUploaded || !entity.reportDownloadingUrl}
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadAuditReport(entity);
                            }}
                        />

                        <EditButton
                            hideText={true}
                            size='small'
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />

                        <CustomDeleteButton
                            hideText={true}
                            size='small'
                            recordItemId={entity.id}
                            mutationMode='undoable'
                            onClick={onClickCapture}
                        />
                    </Space>
                ),
            },
        ],
        [t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(columns, tBase, 'audits');

    return (
        <List title={t('audits.titles.list')} canCreate={canCreate}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <AuditsFilters formProps={searchFormProps} />
                        </Col>
                        <Col span={18}>
                            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                                <Col xs={12}>
                                    <AuditScoreResultChart
                                        tBase={tBase}
                                        data={auditScoreResultChartData?.data}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <AuditNcsChart
                                        tBase={tBase}
                                        audits={audits}
                                        filters={filters}
                                    />
                                </Col>
                                <Col xs={24} className='text-right'>
                                    <TableSettingsButton
                                        title={t(`${tBase}.tableSettings.title`)}
                                        columnsList={columnsList}
                                        settings={settings}
                                        onSettingsChange={onSettingsChange}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                        {...tableProps}
                                        rowKey='id'
                                        rowClassName='cursor-pointer'
                                        className='table-with-header-cell-nowrap'
                                        onRow={(entity) => {
                                            return {
                                                onClick: () => show('audits', entity.id),
                                            };
                                        }}
                                    >
                                        {columns.map(({ name, title, hidden, ...columnProps }) => {
                                            const key = Array.isArray(name) ? name.join('.') : name;
                                            return (
                                                <Table.Column
                                                    {...columnProps}
                                                    dataIndex={name}
                                                    key={key}
                                                    hidden={hidden ?? !settings[key]}
                                                    title={title ?? t(`${tBase}.fields.${key}`)}
                                                />
                                            );
                                        })}
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </List>
    );
};
