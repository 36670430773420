import { useApiUrl, useCan, useCustom } from '@refinedev/core';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import type { IAuditNCInfo, IAuditVersion } from '@/interfaces/audits';

const useAuditNCItems = () => {
    const apiUrl = useApiUrl();
    const { data: { can: canGetAudit } = {} } = useCan({
        resource: 'audits',
        action: 'get',
    });

    const { data } = useCustom<IAuditNCInfo[]>({
        url: `${apiUrl}/audits/ncs`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetAudit,
        },
    });

    return data?.data;
};

export default useAuditNCItems;
