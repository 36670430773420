import { useMemo, type FunctionComponent } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useShow,
    useCan,
    useApiUrl,
    useCustom,
    CrudFilter,
} from '@refinedev/core';
import { Show } from '@refinedev/antd';
import { Row, Col } from 'antd';

import { REACT_QUERY_MICRO_CACHE_TTL } from '@/constants';

import AuditNcsChart from '@/components/AuditNcsChart';
import AuditScoreResultChart from '@/components/AuditScoreResultChart';
import AuditBaseCard from './AuditBaseCard';
import AuditSummaryCard from './AuditSummaryCard';
import AuditStats from './AuditStats';

import type { IAudit, IAuditScoreResultChartData } from '@/interfaces/audits';

const tBase = 'audits.show';

export const AuditsShow: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { data: { can: canEdit } = {} } = useCan({ resource: 'audits', action: 'edit' });

    const { queryResult } = useShow<IAudit>();
    const { data, isLoading } = queryResult;
    const audit = data?.data;
    const audits = useMemo(() => (audit ? [audit] : undefined), [audit]);
    const filters: CrudFilter[] = useMemo(
        () => [
            { field: 'typeId', operator: 'eq', value: audit?.typeId ?? audit?.type?.id },
            {
                field: 'productTypeId',
                operator: 'eq',
                value: audit?.productTypeId ?? audit?.productType?.id,
            },
            { field: 'clientId', operator: 'eq', value: audit?.clientId ?? audit?.client?.id },
        ],
        [audit],
    );
    const auditId = audit?.id;

    const { data: auditScoreResultChartData } = useCustom<IAuditScoreResultChartData>({
        url: `${apiUrl}/audits/average`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: auditId != null && !isLoading,
        },
        config: {
            filters: [{ field: 'id', operator: 'eq', value: auditId }],
        },
    });

    return (
        <Show
            title={t('audits.titles.show')}
            canEdit={canEdit}
            isLoading={isLoading}
            contentProps={{
                style: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    boxShadow: 'none',
                },
                styles: {
                    body: {
                        padding: 0,
                    },
                },
            }}
        >
            <AuditStats tBase={tBase} audit={audit} />

            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                <Col xs={12}>
                    <AuditScoreResultChart tBase={tBase} data={auditScoreResultChartData?.data} />
                </Col>
                <Col xs={12}>
                    <AuditNcsChart tBase={tBase} audits={audits} filters={filters} />
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col xs={12}>
                    <AuditBaseCard tBase={tBase} audit={audit} />
                </Col>
                <Col xs={12}>
                    <AuditSummaryCard tBase={tBase} audit={audit} />
                </Col>
            </Row>
        </Show>
    );
};
