import { useEffect, useMemo, useState, type FunctionComponent } from 'react';
import { Card, Typography } from 'antd';
import { CrudFilter, useTranslate } from '@refinedev/core';
import { InfoCircleOutlined } from '@ant-design/icons';

import Chart from './Chart';

import useAuditNCItems from '@/hooks/useAuditNCItems';
import getAuditNcsChartItems from '@/utils/audit/getAuditNcsChartItems';
import type { IAudit } from '@/interfaces/audits';
import type { ICompany } from '@/interfaces/companies';

import c from './AuditNcsChart.module.css';

export type Props = {
    tBase: string;
    audits?: IAudit[];
    filters?: CrudFilter[];
};

const AuditNcsChart: FunctionComponent<Props> = ({ tBase, audits, filters }) => {
    const t = useTranslate();

    const [auditTypeId, setAuditTypeId] = useState<IAudit['typeId'] | undefined>(undefined);
    const [productTypeId, setProductTypeId] = useState<IAudit['productTypeId'] | undefined>(
        undefined,
    );
    const [clientId, setClientId] = useState<ICompany['id'] | undefined>(undefined);

    useEffect(() => {
        filters?.forEach(
            ({
                // @ts-ignore
                field,
                value,
            }) => {
                if (field === 'typeId') {
                    setAuditTypeId(value);
                } else if (field === 'productTypeId') {
                    setProductTypeId(value);
                } else if (field === 'clientId') {
                    setClientId(value);
                }
            },
        );
    }, [filters]);

    const ncs = useAuditNCItems();

    const data = useMemo(() => getAuditNcsChartItems(ncs, audits), [audits, ncs]);

    return (
        <Card
            title={
                <div className={c.cardTitle}>
                    <InfoCircleOutlined />
                    <Typography.Text className={c.cardTitleText}>
                        {t(`${tBase}.chart.ncs.title`)}
                    </Typography.Text>
                </div>
            }
            className={c.card}
        >
            <Chart data={data} />
        </Card>
    );
};

export default AuditNcsChart;
